import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="NIMBUS: Barnabus Bane" />
    <div style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          // maxHeight: 600,
        }}
        aspectRatio={16/9}
        layout="fullWidth"
        // This is a presentational image, so the alt should be an empty string
        alt=""
        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
        src="../images/NIMBUS-Bane.png"
        formats={["auto", "webp", "avif"]}
      />
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          // This centers the other elements inside the hero component
          placeItems: "center",
          display: "grid",
        }}
      >
        {/* Any content here will be centered in the component */}
        {/* <h1>Hero text</h1> */}
      </div>
    </div>
    <section className="info">
        <h2>Praktische Informationen</h2>
        <div className="info-group">
          <h3>Datum</h3>
          <span>27.-29. Mai 2022</span>
        </div>
        <div className="info-group">
          <h3>Location</h3>
          <span>München</span>
        </div>
        <div className="info-group">
          <h3>Teilnehmerzahl</h3>
          <span>40</span>
        </div>
        <div className="info-group">
          <h3>Themen</h3>
          <span>Urban Fantasy, City Larp, Praktikum, Magie, Agemanten, Intrigen, Verbrechen, Zusammenhalt, Abenteuer, Mysterien, (düstere) Geheimnisse, dunkle Magie</span>
        </div>
        <div className="info-group">
          <h3>City Larp</h3>
          <span>Das Spiel findet inmitten von München statt. Es gibt festgelegte Spielbereiche, die teilweise exklusiv für das Larp reserviert sind. Du wirst dich durch München bewegen und dabei auch etwas von der historischen Stadt sehen. Es werden keine aufmerksamkeit erregenden Szenen in der Öffentlichkeit stattfinden. Vielmehr geht es um die Geheimhaltung der magischen Welt.</span>
        </div>
        <div className="info-group">
          <h3>Ticket</h3>
          <p>40 Plätze</p>
          <p>Das Ticket beinhaltet die Teilnahme am Spiel (inkl. Workshops, Plots, Zugang zu den Spielbereichen, NPCs, uvm.) und einen für dich erstellten bzw. weiterentwickelten Charakter. </p>
          <p>Das Ticket beinhalten nicht die Unterkunft und Verpflegung in München, Anreise und ÖPNV.</p>
          <p><b>Standard Ticket:</b> 190€</p>
          <p><b>Sponsoren Ticket:</b> 240€</p>
          <p><b>Support-Ticket:</b> 90€</p>
          </div>
        
    </section>
    <div className="center">
      <a className="btn" href="https://docs.google.com/document/d/1NrncXfyEwdLQ1g-OMggTUGt4C05LHOYQZ-i946ofwJM/edit?usp=sharing" target="_blank" rel="noreferrer">Zum Guide</a>
    </div>
  </Layout>
)

export default IndexPage
